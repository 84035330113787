import { AppAction } from '../types';
import { Guest, RepeatableGuest } from './guestsManagementTypes';

export const GUESTS_MANAGEMENT_DELETE_PAST_GUEST =
  'GUESTS_MANAGEMENT_DELETE_PAST_GUEST';
export const GUESTS_MANAGEMENT_GET_ALL_PAST_GUESTS =
  'GUESTS_MANAGEMENT_GET_ALL_PAST_GUESTS';
export const GUESTS_MANAGEMENT_DELETE_FUTURE_GUEST =
  'GUESTS_MANAGEMENT_DELETE_FUTURE_GUEST';
export const GUESTS_MANAGEMENT_GET_ALL_FUTURE_GUESTS =
  'GUESTS_MANAGEMENT_GET_ALL_FUTURE_GUESTS';
export const GUESTS_MANAGEMENT_GET_ALL_GUARD_PAST_GUESTS =
  'GUESTS_MANAGEMENT_GET_ALL_GUARD_PAST_GUESTS';
export const GUESTS_MANAGEMENT_GET_ALL_GUARD_FUTURE_GUESTS =
  'GUESTS_MANAGEMENT_GET_ALL_GUARD_FUTURE_GUESTS';
export const GUESTS_MANAGEMENT_GUARD_SET_ACTIVE_PAGE =
  'GUESTS_MANAGEMENT_GUARD_SET_ACTIVE_PAGE';
export const GUESTS_MANAGEMENT_GUARD_SET_COUNT_TO_SHOW =
  'GUESTS_MANAGEMENT_GUARD_SET_COUNT_TO_SHOW';
export const GUESTS_MANAGEMENT_GUARD_SET_PAGES =
  'GUESTS_MANAGEMENT_GUARD_SET_PAGES';
export const GUESTS_MANAGEMENT_GUARD_SET_FULL_NAME_FILTER =
  'GUESTS_MANAGEMENT_GUARD_SET_FULL_NAME_FILTER';
export const GUESTS_MANAGEMENT_GUARD_SET_PHONE_FILTER =
  'GUESTS_MANAGEMENT_GUARD_SET_PHONE_FILTER';
export const GUESTS_MANAGEMENT_GUARD_SET_LICENSE_NUMBER_FILTER =
  'GUESTS_MANAGEMENT_GUARD_SET_LICENSE_NUMBER_FILTER';
export const GUESTS_MANAGEMENT_SET_PAGES = 'GUESTS_MANAGEMENT_SET_PAGES';
export const GUESTS_MANAGEMENT_GUARD_SET_COMPANY_NAME_FILTER =
  'GUESTS_MANAGEMENT_GUARD_SET_COMPANY_NAME_FILTER';
export const GUESTS_MANAGEMENT_GUARD_SET_BUILDING_FILTER =
  'GUESTS_MANAGEMENT_GUARD_SET_BUILDING_FILTER';
export const GUESTS_MANAGEMENT_GUARD_SET_ARRIVAL_DATE_FILTER =
  'GUESTS_MANAGEMENT_GUARD_SET_ARRIVAL_DATE_FILTER';
export const GUESTS_MANAGEMENT_SET_ACTIVE_PAGE =
  'GUESTS_MANAGEMENT_SET_ACTIVE_PAGE';
export const GUESTS_MANAGEMENT_MY_GUESTS_SET_COUNT_TO_SHOW =
  'GUESTS_MANAGEMENT_MY_GUESTS_SET_COUNT_TO_SHOW';
export const GUESTS_MANAGEMENT_MY_GUESTS_SET_FULL_NAME_FILTER =
  'GUESTS_MANAGEMENT_MY_GUESTS_SET_FULL_NAME_FILTER';
export const GUESTS_MANAGEMENT_MY_GUESTS_SET_ARRIVAL_DATE_FILTER =
  'GUESTS_MANAGEMENT_MY_GUESTS_SET_ARRIVAL_DATE_FILTER';
export const GUESTS_MANAGEMENT_MY_GUESTS_SET_PAGES =
  'GUESTS_MANAGEMENT_MY_GUESTS_SET_PAGES';
export const GUESTS_MANAGEMENT_MY_GUESTS_SET_ACTIVE_PAGE =
  'GUESTS_MANAGEMENT_MY_GUESTS_SET_ACTIVE_PAGE';

export const GUESTS_MANAGEMENT_SET_LICENSE_NUMBER_FILTER =
  'GUESTS_MANAGEMENT_SET_LICENSE_NUMBER_FILTER';
export const GUESTS_MANAGEMENT_SET_PHONE_FILTER =
  'GUESTS_MANAGEMENT_SET_PHONE_FILTER';
export const GUESTS_MANAGEMENT_SET_REPEATABLE_GUESTS =
  'GUESTS_MANAGEMENT_SET_REPEATABLE_GUESTS';
export const GUESTS_MANAGEMENT_REPEATABLE_GUESTS_SET_ACTIVE_PAGE =
  'GUESTS_MANAGEMENT_REPEATABLE_GUESTS_SET_ACTIVE_PAGE';
export const GUESTS_MANAGEMENT_REPEATABLE_GUESTS_SET_COUNT_TO_SHOW =
  'GUESTS_MANAGEMENT_REPEATABLE_GUESTS_SET_COUNT_TO_SHOW';
export const GUESTS_MANAGEMENT_REPEATABLE_GUESTS_SET_PAGES =
  'GUESTS_MANAGEMENT_REPEATABLE_GUESTS_SET_PAGES';

export const GUESTS_MANAGEMENT_REPEATABLE_GUESTS_SET_FULL_NAME_FILTER =
  'GUESTS_MANAGEMENT_REPEATABLE_GUESTS_SET_FULL_NAME_FILTER';

export interface guestsManagementSetRepeatableGuestsActionType {
  type: typeof GUESTS_MANAGEMENT_SET_REPEATABLE_GUESTS;
  guests: RepeatableGuest[];
}

export interface guestsManagementRepeatableGuestsSetActivePageActionType {
  type: typeof GUESTS_MANAGEMENT_REPEATABLE_GUESTS_SET_ACTIVE_PAGE;
  page: number;
}

export interface guestsManagementRepeatableGuestsSetCountToShowActionType {
  type: typeof GUESTS_MANAGEMENT_REPEATABLE_GUESTS_SET_COUNT_TO_SHOW;
  count: number;
}

export interface guestsManagementRepeatableGuestsSetPagesActionType {
  type: typeof GUESTS_MANAGEMENT_REPEATABLE_GUESTS_SET_PAGES;
  pages: number;
}

export interface guestsManagementRepeatableGuestsSetFullNameFilterActionType {
  type: typeof GUESTS_MANAGEMENT_REPEATABLE_GUESTS_SET_FULL_NAME_FILTER;
  fullName: string;
}

export interface guestsManagementSetPhoneFilterActionType {
  type: typeof GUESTS_MANAGEMENT_SET_PHONE_FILTER;
  phone: string;
}

export interface guestsManagementGetAllFutureGuestsActionType {
  type: typeof GUESTS_MANAGEMENT_GET_ALL_FUTURE_GUESTS;
  payload: Guest[];
}

export interface guestsManagementSetActivePageActionType {
  type: typeof GUESTS_MANAGEMENT_SET_ACTIVE_PAGE;
  page: number;
}

export interface guestsManagementGetAllPastGuestsActionType {
  type: typeof GUESTS_MANAGEMENT_GET_ALL_PAST_GUESTS;
  payload: Guest[];
}

export interface guestsManagementSetLicenseNumberFilterActionType {
  type: typeof GUESTS_MANAGEMENT_SET_LICENSE_NUMBER_FILTER;
  licenseNumber: string;
}

export interface guestsManagementGetAllFutureGuestsActionType {
  type: typeof GUESTS_MANAGEMENT_GET_ALL_FUTURE_GUESTS;
  payload: Guest[];
}

export interface guestsManagementSetActivePageActionType {
  type: typeof GUESTS_MANAGEMENT_SET_ACTIVE_PAGE;
  page: number;
}

export interface guestsManagementGetAllPastGuestsActionType {
  type: typeof GUESTS_MANAGEMENT_GET_ALL_PAST_GUESTS;
  payload: Guest[];
}

export interface guestsManagementGetAllGuardFutureGuestsActionType {
  type: typeof GUESTS_MANAGEMENT_GET_ALL_GUARD_FUTURE_GUESTS;
  payload: Guest[];
}

export interface guestsManagementGetAllGuardPastGuestsActionType {
  type: typeof GUESTS_MANAGEMENT_GET_ALL_GUARD_PAST_GUESTS;
  payload: Guest[];
}

export interface guestsManagementRemoveFutureGuestActionType {
  type: typeof GUESTS_MANAGEMENT_DELETE_FUTURE_GUEST;
  id: number;
}

export interface guestsManagementRemovePastGuestActionType {
  type: typeof GUESTS_MANAGEMENT_DELETE_PAST_GUEST;
  id: number;
}

export interface guestsManagementGuardSetActivePageActionType {
  type: typeof GUESTS_MANAGEMENT_GUARD_SET_ACTIVE_PAGE;
  page: number;
}

export interface guestsManagementGuardSetFullNameFilterActionType {
  type: typeof GUESTS_MANAGEMENT_GUARD_SET_FULL_NAME_FILTER;
  fullName: string;
}

export interface guestsManagementGuardSetPhoneFilterActionType {
  type: typeof GUESTS_MANAGEMENT_GUARD_SET_PHONE_FILTER;
  phone: string;
}

export interface guestsManagementGuardSetLicenseNumberFilterActionType {
  type: typeof GUESTS_MANAGEMENT_GUARD_SET_LICENSE_NUMBER_FILTER;
  licenseNumber: string;
}

export interface guestsManagementMyGuestsSetFullNameFilterActionType {
  type: typeof GUESTS_MANAGEMENT_MY_GUESTS_SET_FULL_NAME_FILTER;
  fullName: string;
}

export interface guestsManagementGuardSetCompanyNameFilterActionType {
  type: typeof GUESTS_MANAGEMENT_GUARD_SET_COMPANY_NAME_FILTER;
  companyId: { value: string; label: string };
}

export interface guestsManagementGuardSetBuildingFilterActionType {
  type: typeof GUESTS_MANAGEMENT_GUARD_SET_BUILDING_FILTER;
  buildingId: { value: string; label: string };
}

export interface guestsManagementGuardSetArrivalDateFilterActionType {
  type: typeof GUESTS_MANAGEMENT_GUARD_SET_ARRIVAL_DATE_FILTER;
  arrivalDate: Date[];
}

export interface guestsManagementMyGuestsSetArrivalDateFilterActionType {
  type: typeof GUESTS_MANAGEMENT_MY_GUESTS_SET_ARRIVAL_DATE_FILTER;
  arrivalDate: Date[];
}

export interface guestsManagementGuardSetCountToShowActionType {
  type: typeof GUESTS_MANAGEMENT_GUARD_SET_COUNT_TO_SHOW;
  count: number;
}

export interface guestsManagementMyGuestsSetCountToShowActionType {
  type: typeof GUESTS_MANAGEMENT_MY_GUESTS_SET_COUNT_TO_SHOW;
  count: number;
}

export interface guestsManagementGuardSetPagesActionType {
  type: typeof GUESTS_MANAGEMENT_GUARD_SET_PAGES;
  pages: number;
}

export interface guestsManagementMyGuestsSetPagesActionType {
  type: typeof GUESTS_MANAGEMENT_MY_GUESTS_SET_PAGES;
  pages: number;
}

export interface guestsManagementSetPagesActionType {
  type: typeof GUESTS_MANAGEMENT_SET_PAGES;
  pages: number;
}

export interface guestsManagementMyGuestsSetActivePageActionType {
  type: typeof GUESTS_MANAGEMENT_MY_GUESTS_SET_ACTIVE_PAGE;
  page: number;
}

export type GuestsManagementAction =
  | guestsManagementRemovePastGuestActionType
  | guestsManagementGetAllPastGuestsActionType
  | guestsManagementRemoveFutureGuestActionType
  | guestsManagementGetAllFutureGuestsActionType
  | guestsManagementGetAllGuardPastGuestsActionType
  | guestsManagementGetAllGuardFutureGuestsActionType
  | guestsManagementGuardSetActivePageActionType
  | guestsManagementGuardSetCountToShowActionType
  | guestsManagementGuardSetPagesActionType
  | guestsManagementGuardSetFullNameFilterActionType
  | guestsManagementGuardSetCompanyNameFilterActionType
  | guestsManagementGuardSetBuildingFilterActionType
  | guestsManagementGuardSetArrivalDateFilterActionType
  | guestsManagementMyGuestsSetArrivalDateFilterActionType
  | guestsManagementMyGuestsSetFullNameFilterActionType
  | guestsManagementMyGuestsSetCountToShowActionType
  | guestsManagementMyGuestsSetPagesActionType
  | guestsManagementMyGuestsSetActivePageActionType
  | guestsManagementSetLicenseNumberFilterActionType
  | guestsManagementGuardSetLicenseNumberFilterActionType
  | guestsManagementSetRepeatableGuestsActionType
  | guestsManagementRepeatableGuestsSetActivePageActionType
  | guestsManagementRepeatableGuestsSetCountToShowActionType
  | guestsManagementRepeatableGuestsSetPagesActionType
  | guestsManagementRepeatableGuestsSetFullNameFilterActionType
  | guestsManagementSetPhoneFilterActionType
  | guestsManagementGuardSetPhoneFilterActionType;

export type GuestsManagementThunk<T = void> = AppAction<
  GuestsManagementAction,
  T
>;
